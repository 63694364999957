import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from './loading.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './loading.component.html',
  styleUrls: [`./loading.component.css`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
    private subs: Subscription[] = [];

    isShow:boolean = false;
    constructor(
        private loadingService: LoadingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        
    }

    ngOnInit(): void {
        this.subs.push(
            this.loadingService.getShow().subscribe((v:boolean) => {
                this.isShow = v;
                this.changeDetectorRef.detectChanges()
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}
